.button_section_chat .form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
}
.wauto label{
    width: auto !important;
  }
  .wdth--1001 {
	width: 100%;
}
.wdth--1001 i {
	float: right;
	margin-right: 11px;
	margin-top: -26px;
	position: relative;
	font-size: 16px;
}